import axios from './index'

export default {
  getSosyalGuvenceDurumuList() {
    return axios.get('/data/sagliknet/sosyalGuvenceDurumu')
      .then((response) => {
        return response.data;
      }).catch((error) => {
        return Promise.reject(error);
      });
  },
  getVakaTuruList() {
    return axios.get('/data/sagliknet/vakaTuru')
      .then((response) => {
        return response.data;
      }).catch((error) => {
        return Promise.reject(error);
      });
  },
  getSagliknetTableData(url) {
    return axios.get(url)
      .then((response) => {
        return response.data;
      }).catch((error) => {
        return Promise.reject(error);
      });
  },
  getSutList(search) {
    return axios.get('/data/sagliknet/sutAsync?keyword=' + search)
      .then((response) => {
        return response.data;
      }).catch((error) => {
        return Promise.reject(error);
      });
  },
  getIcdList(search) {
    return axios.get('/data/shared/Icd10Async/sagliknet?keyword=' + search)
      .then((response) => {
        return response.data;
      }).catch((error) => {
        return Promise.reject(error);
      });
  },
  getSagliknetKlinikKodlari() {
    return axios.get('/data/sagliknet/klinikKodu/')
      .then((response) => {
        return response.data;
      }).catch((error) => {
        return Promise.reject(error);
      });
  },
  transferToSagliknet(data) {
    return axios.post('/sagliknet/transfer', data)
      .then((response) => {
        return response.data;
      }).catch((error) => {
        return Promise.reject(error);
      });
  }
}
