<template>
  <b-container fluid>
    <ValidationObserver ref="passwordSettingsObserver" v-slot="{ invalid }">
      <form>
        <iq-card>
          <template v-slot:body>
            <b-row>
              <b-form-group v-if="loggedStaff" class="col-md-12" :label="$t('OldPassword')" label-for="OldPassword">
                <ValidationProvider :name="$t('OldPassword')" rules="required" v-slot="{ errors }">
                  <b-form-input v-model="oldPassword" type="password" :class="(errors.length > 0 ? ' is-invalid' : '')"></b-form-input>
                  <div class="invalid-feedback">
                    <span>{{ $t('ValidationMessage.CannotBeEmpty') }}</span>
                  </div>
                </ValidationProvider>
              </b-form-group>

              <b-form-group class="col-md-12" :label="$t('NewPassword')" label-for="NewPassword">
                <ValidationProvider :name="$t('NewPassword')" rules="required" v-slot="{ errors }">
                  <b-form-input v-model="newPassword" type="password" :class="(errors.length > 0 ? ' is-invalid' : '')"></b-form-input>
                  <div class="invalid-feedback">
                    <span>{{ $t('ValidationMessage.CannotBeEmpty') }}</span>
                  </div>
                </ValidationProvider>
              </b-form-group>

              <b-form-group class="col-md-12" :label="$t('NewPasswordAgain')" label-for="NewPasswordAgain">
                <ValidationProvider :name="$t('NewPasswordAgain')" rules="required" v-slot="{ errors }">
                  <b-form-input v-model="confirmPassword" type="password" :class="(errors.length > 0 ? ' is-invalid' : '')"></b-form-input>
                  <div class="invalid-feedback">
                    <span>{{ $t('ValidationMessage.CannotBeEmpty') }}</span>
                  </div>
                </ValidationProvider>
              </b-form-group>
            </b-row>
          </template>
        </iq-card>
        <modal-footer-button :set-is-submitting="isSubmitting"
                             @submit="submitChangePasswordForm"></modal-footer-button>
      </form>
    </ValidationObserver>
  </b-container>
</template>
<script>
  import staffService from '../../../services/staff'
  import ModalFooterButton from '../../../components/shared/ModalFooterButton'
  export default {
    name: 'PasswordSettings',
    components: {
      ModalFooterButton
    },
    props: {
      staffId: String
    },
    data() {
      return {
        //staffId: '',
        newPassword: '',
        confirmPassword: '',
        oldPassword: '',
        loggedStaff: false,
        isSubmitting: false
      }
    },
    methods: {
      isLoggedStaff() {
        staffService.isLoggedStaff(this.staffId)
          .then(response => {
            this.loggedStaff = response;
          });
      },
      async submitChangePasswordForm() {

        const isValid = await this.$refs.passwordSettingsObserver.validate();
        if (!isValid) {
          this.$toastr.error(this.$t("ValidationMessage.EmptyAndValidateError"));
          return;
        }

        if (this.newPassword != this.confirmPassword) {
          this.$toastr.error(
            this.$t("ForgotPasswordPage.NewPasswordsNotMatching")
          );
        } else {
          let url = '/Staff/ChangePassword';

          let params = {};

          if (this.isProfilePage) {
            params = {
              fromProfilePage: true,
              newPassword: this.newPassword,
              oldPassword: this.oldPassword
            }
          }
          else {
            params = {
              staffId: this.staffId,
              fromProfilePage: false,
              newPassword: this.newPassword,
              oldPassword: this.oldPassword
            }
          }

          this.isSubmitting = true;

          staffService.submitChangePasswordForm(url, params)
            .then(response => {
              this.isSubmitting = false;
              if (response.success) {
                this.$emit('closeModal');
                this.$toastr.success(this.$t("Success"));

              }
              else if (response.message == "OldPasswordNotMatching") {
                this.$toastr.error(this.$t("OldPasswordNotMatching"));
              }
              else {
                this.$toastr.error(this.$t("Error"));
              }
            }).finally(() => { this.isSubmitting = false; });
        }
      },
    },
    computed: {
      isProfilePage: function () {
        return this.$route.name == 'staffProfile.passwordSettings' ? true : false;
      }
    },
    mounted: function () {
      this.staffId = this.$router.currentRoute.params.staffId;
      this.isLoggedStaff();
    }
  }
</script>
