<template>
  <b-container fluid>
    <ValidationObserver ref="newStaffObserver" v-slot="{ invalid }">
      <form>
        <b-row>
          <b-col lg="12">
            <b-row>
              <b-form-group class="col-md-4" :label="$t('Name')" label-for="name">
                <ValidationProvider :name="$t('Name')" rules="required" v-slot="{ errors }">
                  <b-form-input :class="(errors.length > 0 ? ' is-invalid' : '')" v-model="model.name" type="text"></b-form-input>
                  <b-form-invalid-feedback> {{ $t('ValidationMessage.CannotBeEmpty') }} </b-form-invalid-feedback>
                </ValidationProvider>
              </b-form-group>

              <b-form-group class="col-md-4" :label="$t('Phone')" label-for="phone">
                <b-form-input v-model="model.phoneNumber" type="text"> </b-form-input>
              </b-form-group>

              <b-form-group class="col-md-4" :label="$t('Email')" label-for="email">
                <ValidationProvider :name="$t('Email')" rules="required|email" v-slot="{ errors }">
                  <b-form-input :class="(errors.length > 0 || emailIsExists == true ? ' is-invalid' : '')" v-model="model.email" type="email"></b-form-input>
                  <b-form-invalid-feedback>
                    {{ emailIsExists == true ? $t('ValidationMessage.ThisEmailAlreadyExist') : $t('ValidationMessage.CannotBeEmpty')  }}
                  </b-form-invalid-feedback>
                </ValidationProvider>
              </b-form-group>

              <b-form-group v-if="!isEdit" class="col-md-4" :label="$t('Password')" label-for="password">
                <ValidationProvider :name="$t('Password')" rules="required" v-slot="{ errors }">
                  <b-form-input :class="(errors.length > 0 ? ' is-invalid' : '')" v-model="model.passwordHash" type="password"></b-form-input>
                  <b-form-invalid-feedback> {{ $t('ValidationMessage.CannotBeEmpty') }} </b-form-invalid-feedback>
                </ValidationProvider>
              </b-form-group>

              <b-form-group class="col-md-4" :label="$t('SystemLanguage')" label-for="systemlanguage">
                <v-select transition="" v-model="model.culture"
                          :reduce="s => s.id" label="name"
                          :options="cultures">
                  <template v-slot:no-options>
                    {{$t('NoMatchingOptionsMessage')}}
                  </template>
                </v-select>
              </b-form-group>

              <b-form-group class="col-md-4" :label="$t('CalendarColor')" label-for="calendarcolor">
                <v-select transition="" v-model="model.calendarColor"
                          :reduce="s => s.id" label="name"
                          :options="colors">
                  <template v-slot:no-options>
                    {{$t('NoMatchingOptionsMessage')}}
                  </template>
                </v-select>
              </b-form-group>

              <b-form-group class="col-md-4" :label="$t('ShowInList')" label-for="showinlist">
                <v-select transition="" v-model="model.showInList"
                          :reduce="s => s.id" label="name"
                          :options="yesNoList">
                  <template v-slot:no-options>
                    {{$t('NoMatchingOptionsMessage')}}
                  </template>
                </v-select>
              </b-form-group>

              <b-form-group class="col-md-4" :label="$t('CalendarDisplayOrder')" label-for="calendarDisplayOrder">
                <b-form-input class="form-control"
                              type="number"
                              v-model="model.calendarDisplayOrder"
                              min="0">
                </b-form-input>
              </b-form-group>

              <b-form-group class="col-md-4" :label="$t('LoginEnabled')" label-for="loginenabled">
                <v-select transition="" v-model="model.loginEnabled"
                          :reduce="s => s.id" label="name"
                          :clearable="false"
                          :options="yesNoList">
                  <template v-slot:no-options>
                    {{$t('NoMatchingOptionsMessage')}}
                  </template>
                </v-select>
              </b-form-group>

              <b-form-group class="col-md-4" :label="$t('IsDoctor')" label-for="isDoctor">
                <v-select transition="" v-model="model.isDoctor"
                          :reduce="s => s.id" label="name"
                          :options="yesNoList">
                  <template v-slot:no-options>
                    {{$t('NoMatchingOptionsMessage')}}
                  </template>
                </v-select>
              </b-form-group>

              <b-form-group class="col-md-4" :label="$t('AuthorityGroup')" label-for="authGroups">
                <ValidationProvider :name="$t('AuthorityGroup')" rules="required" v-slot="{ errors }">
                  <v-select transition="" v-model="branch.authGroupID"
                            :reduce="s => s.id" label="name"
                            :options="authGroups"
                            :class="(errors.length > 0 ? ' is-invalid' : '')"
                            required>
                    <template v-slot:no-options>
                      {{$t('NoMatchingOptionsMessage')}}
                    </template>
                  </v-select>
                  <b-form-invalid-feedback> {{ $t('ValidationMessage.CannotBeEmpty') }} </b-form-invalid-feedback>
                </ValidationProvider>
              </b-form-group>

              <b-form-group class="col-md-4" :label="$t('ShowInCalendar')" label-for="showInCalendar">
                <v-select transition="" v-model="branch.showInCalendar"
                          :reduce="s => s.id" label="name"
                          :options="yesNoList">
                  <template v-slot:no-options>
                    {{$t('NoMatchingOptionsMessage')}}
                  </template>
                </v-select>
              </b-form-group>
            </b-row>
          </b-col>
        </b-row>
        <b-row v-if="branchIsSaglikNetActive && model.isDoctor">
          <b-col lg="12">
            <hr />
            <h5 class="text-primary mb-3">{{$t('ENabiz')}}</h5>

            <b-row>
              <b-form-group class="col-md-4" :label="$t('DoctorIdentificationNumber')" label-for="doctorIdentificationNumber">
                <b-form-input class="form-control"
                              type="text"
                              maxlength="100"
                              v-model="model.hekimKimlikNumarasi">
                </b-form-input>
              </b-form-group>
              <b-form-group class="col-md-4" :label="$t('USSUserName')" label-for="ussUserName">
                <b-form-input class="form-control"
                              type="text"
                              maxlength="100"
                              v-model="model.ussKullaniciAdi">
                </b-form-input>
              </b-form-group>
              <b-form-group class="col-md-4" :label="$t('USSPassword')" label-for="ussPassword">
                <b-form-input class="form-control"
                              type="password"
                              maxlength="100"
                              v-model="model.ussParola">
                </b-form-input>
              </b-form-group>
              <b-form-group class="col-md-4" :label="$t('ClinicCkysCode')" label-for="clinicCkysCode">
                <b-form-input class="form-control"
                              type="number"
                              maxlength="100"
                              v-model="model.kurumCkysKodu">
                </b-form-input>
              </b-form-group>
              <b-form-group class="col-md-4" :label="$t('ClinicCode')" label-for="clinicCode">
                <v-select transition="" v-model="model.klinikKodu"
                          :reduce="s => s.id" label="name"
                          :options="clinicCodes">
                  <template v-slot:no-options>
                    {{$t('NoMatchingOptionsMessage')}}
                  </template>
                </v-select>
              </b-form-group>
            </b-row>
          </b-col>
        </b-row>

        <modal-footer-button :set-is-submitting="isSubmitting"
                             @submit="submitStaffForm"></modal-footer-button>
      </form>
    </ValidationObserver>
  </b-container>
</template>
<script>
  import staffServices from '../../../services/staff';
  import sagliknetServices from '../../../services/sagliknet';
  import commonServices from '../../../services/common';

  import { refreshSmartDuoData } from '../../../helpers/dataRefresher'
  import ModalFooterButton from '../../../components/shared/ModalFooterButton';
  export default {
    name: 'NewStaff',
    components: {
      ModalFooterButton
    },
    props: {
      staffId: String
    },
    data() {
      return {
        model: {
          id: '',
          name: '',
          userName: '',
          email: '',
          passwordHash: '',
          phoneNumber: '',
          loginEnabled: true,
          culture: 'tr-TR',
          showInCalendar: true,
          showInList: true,
          calendarDisplayOrder: null,
          calendarColor: "#4B77BE",
          staff_Branches: [],
          authGroupID: '',
          isDoctor: null,
          uSSKullaniciAdi: '',
          ussParola: '',
          kurumCkysKodu: '',
          hekimKimlikNumarasi: '',
          klinikKodu: '',
        },

        branch: { branchID: '', authGroupID: '', showInCalendar: true, isDefault: true, isOwner: false, isWorker: true },

        isEdit: false,
        emailIsExists: false,
        isSubmitting: false,
        cultures: [],
        colors: [],
        yesNoList: [],
        authGroups: [],
        clinicCodes: [],
        branchIsSaglikNetActive: false
      }
    },
    methods: {
      getCultures() {
        commonServices.getCultureList().then((response) => {
          if (response) {
            this.cultures = response;
          }
        })
      },
      getAuthGroups() {
        staffServices.getAuthGroups().then((response) => {
          if (response) {
            this.authGroups = response;
          }
        })
      },
      getColors() {
        commonServices.getColors().then((response) => {
          if (response) {
            this.colors = response;
          }
        })
      },
      getTrueFalseList() {
        commonServices.getTrueFalseList().then((response) => {
          if (response) {
            this.yesNoList = response.map(function (v) {
              v.id = v.id === 'true' ? true : false;
              return v;
            });
          }
        })
      },
      async submitStaffForm() {

        const isValid = await this.$refs.newStaffObserver.validate();
        if (!isValid || this.emailIsExists == true) {
          this.$toastr.error(this.$t("ValidationMessage.EmptyAndValidateError"));
          return;
        }

        this.model.staff_Branches = []

        let url = "/staff/new";
        if (this.model.id && this.model.id.length > 0) {
          url = "/staff/edit";
        }

        this.isSubmitting = true;
        this.model.staff_Branches.push(this.branch);

        staffServices.submitStaffForm(this.model, url).then((response) => {
          this.isSubmitting = false;
          if (response) {
            this.$emit('closeModal');
            this.$toastr.success(this.$t("Success"));
            refreshSmartDuoData();
          }
          else {
            this.$toastr.error(this.$t("Error"));
          }
        }).finally(() => { this.isSubmitting = false; });
      },
      getStaff() {
        staffServices.getStaff(this.staffId).
          then((response) => {
            this.model = response;

            if (this.model && this.model.staff_Branches && this.model.staff_Branches.length > 0)
              this.branch = this.model.staff_Branches[0];

          });
      },
      getStaffEmailIsExists() {
        staffServices.getStaffEmailIsExists(this.model.id, this.model.email)
          .then((response) => {
            this.emailIsExists = response;
          })
      },
      getSagliknetKlinikKodlari() {
        sagliknetServices.getSagliknetKlinikKodlari()
          .then(response => {
            this.clinicCodes = response;
          });

      },
    },
    watch: {
      'model.email': function (email) {
        if (email && email.length > 8)
          this.getStaffEmailIsExists();
      }
    },
    computed: {
      currentBranchId() {
        return this.$store.getters['Auth/currentBranchId'];
      },
    },
    mounted() {
      if (this.staffId && this.staffId.length > 0) {
        this.model.id = this.staffId;
        this.getStaff();
        this.isEdit = true;
      }
      else {
        this.branch.branchID = this.currentBranchId;
      }
      this.getCultures();
      this.getColors();
      this.getTrueFalseList();
      this.getAuthGroups();

      this.branchIsSaglikNetActive = this.$store.getters['Auth/branchIsSaglikNetActive'];

      if (this.branchIsSaglikNetActive) {
        this.getSagliknetKlinikKodlari();
      }
    }
  }
</script>
