<template>
  <b-container fluid>
    <b-row>
      <b-col md="4">
        <iq-card v-if="!isEdit">
          <template v-slot:body>
            <b-nav pills fill vertical>
              <b-row>
                <b-col>
                  <div class="user-profile">
                    <div class="profile-img-edit" style=" height: 70px !important; width: 70px !important;">
                      <b-img v-if="model.profilePicture" id="imageHolder" :src="model.profilePicture" class="profile-pic height-150 width-150" alt="profile-pic" style="object-fit: cover; max-width: 100%; max-height: 100%;" />
                      <b-img v-else id="imageHolder" src="https://s3.stellamedi.com/stellamedi-cdn/images/default-user-image.jpg" class="profile-pic height-150 width-150" alt="default-profile-pic" style="object-fit: cover; max-width: 100%; max-height: 100%;" />
                      <div class="p-image" @click="$refs.profile_avatar.click()" style=" right: -10px; bottom: -10px;">
                        <div class="position-relative">
                          <i class="fa fa-pen"></i>
                          <input ref="profile_avatar" type="file" name="profile_avatar" class="h-100 position-absolute" accept=".png, .jpg, .jpeg" @change="onFileChanged" style="opacity: 0;">
                        </div>
                      </div>
                    </div>
                  </div>
                </b-col>
                <b-col style="margin-right: 170px;">
                  <h5 class="text-md-left mt-3 mr-5 font-weight-bold text-body">{{ model.name }}</h5>
                  <span>{{ model.email }}</span>
                </b-col>
              </b-row>
              <b-row class="pt-4" style="inline-size: -webkit-fill-available;">
                <b-col lg="12">
                  <b-button style="color: rgb(8 155 171); border: none; border-bottom: 1px dotted rgb(8,155,171) !important; background:none; border-radius: 0px; padding: 0 0 10px 0;"
                            class="btn btn-block text-left"
                            @click="openEditModel()">
                    <font-awesome-icon icon="fa-regular fa-gear" /> {{ $t("StaffProfile.EditProfile") }}
                  </b-button>
                  <br />
                  <b-button style="color: rgb(8 155 171); border: none; border-bottom: 1px dotted rgb(8,155,171) !important; background:none; border-radius: 0px; padding: 0 0 10px 0;"
                            class="btn btn-block text-left"
                            @click="onNavigatePasswordSettings()">
                    <font-awesome-icon icon="fa-regular fa-lock" /> {{ $t("PasswordSettings") }}
                  </b-button>
                  <br />
                  <b-button style="color: rgb(8 155 171); border: none; border-bottom: 1px dotted rgb(8,155,171) !important; background:none; border-radius: 0px; padding: 0 0 10px 0;"
                            class="btn btn-block text-left"
                            @click="logout()">
                    <font-awesome-icon icon="fa-regular fa-arrow-right-from-bracket" /> {{ $t("Logout") }}
                  </b-button>
                  <br />
                  <br />
                </b-col>
              </b-row>
              <br />
            </b-nav>
          </template>
        </iq-card>
        <iq-card v-if="isEdit">
          <template v-slot:body>
            <b-nav pills fill vertical>
              <b-row>
                <b-col>
                  <b-form-group class="col-md-12" :label="$t('NameSurname')" label-for="fname">
                    <ValidationProvider name="First and last name" rules="required" v-slot="{ errors }">
                      <b-form-input v-model="editModel.name" type="text" :class="(errors.length > 0 ? ' is-invalid' : '')"></b-form-input>
                      <div class="invalid-feedback">
                        <span>{{ $t('ValidationMessage.CannotBeEmpty') }}</span>
                      </div>
                    </ValidationProvider>
                  </b-form-group>
                  <b-form-group class="col-md-12" :label="$t('Phone')" label-for="fphone">
                    <ValidationProvider name="Phone" rules="required" v-slot="{ errors }">
                      <b-form-input v-model="editModel.phoneNumber" type="text" :class="(errors.length > 0 ? ' is-invalid' : '')"></b-form-input>
                      <div class="invalid-feedback">
                        <span>{{ $t('ValidationMessage.CannotBeEmpty') }}</span>
                      </div>
                    </ValidationProvider>
                  </b-form-group>
                  <b-form-group class="col-md-12" :label="$t('Email')" label-for="fmail">
                    <ValidationProvider name="Email" rules="required" v-slot="{ errors }">
                      <b-form-input v-model="editModel.email" type="text" :class="(errors.length > 0 ? ' is-invalid' : '')"></b-form-input>
                      <div class="invalid-feedback">
                        <span>{{ $t('ValidationMessage.CannotBeEmpty') }}</span>
                      </div>
                    </ValidationProvider>
                  </b-form-group>
                </b-col>

              </b-row>
              <b-row>
                <b-col lg="6"></b-col>
                <b-col lg="6" class="d-flex justify-content-around">
                  <b-button v-if="!isSubmitting" type="button" class="btn btn-secondary" @click="onCancelEdit()">{{ $t('Cancel') }}</b-button>
                  <b-overlay :show="isSubmitting"
                             spinner-small
                             spinner-variant="primary"
                             class="d-inline-block">
                    <b-button :disabled="isSubmitting"
                              variant="primary"
                              @click="onSubmitEditForm()">
                      {{ $t('Save') }}
                    </b-button>
                  </b-overlay>
                </b-col>
              </b-row>
            </b-nav>
          </template>
        </iq-card>
      </b-col>
      <b-col>
        <iq-card>
          <template v-slot:headerTitle>
            <h4 class="card-title"><font-awesome-icon icon="fa-regular fa-notes" class="mr-2" />{{ $t("StaffProfile.Notes") }}</h4>
          </template>
          <template v-slot:body>
            <b-nav pills fill vertical>
              <table v-if="model && model.notes && model.notes.length> 0" class="table">
                <thead>
                  <tr>
                    <td style="width: 5%;"><b> {{ $t("Status") }} </b></td>
                    <td style="width: 40%"><b> {{ $t("Note") }}/{{ $t("Task") }} </b></td>
                    <td style="width: 15%"><b> {{ $t("Customer") }} </b></td>
                    <td style="width: 5%"></td>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(note, index) in model.notes"
                      :key="index">
                    <td>
                      <span class="kt-switch kt-switch--sm">
                        <label>
                          <b-form-group class="col-md-6">
                            <b-form-checkbox v-model="note.isCompleted" switch inline size="lg">
                              <span style="font-size:15px !important; vertical-align:middle !important"></span>
                            </b-form-checkbox>
                          </b-form-group>
                        </label>
                      </span>
                    </td>
                    <td>
                      <span class="kt-widget11__sub"> {{ note.notes }} </span>
                    </td>
                    <td>{{ note.customerName }}</td>
                    <td>
                      <button type="button" class="btn btn-primary"
                              @click="getNotes(note.customerID)">
                        <i class="fa fa-search"></i>
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
              <no-data v-else :no-data-text="$t('StaffProfile.NoData')"></no-data>
            </b-nav>
          </template>
        </iq-card>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <iq-card>
          <template v-slot:headerTitle>
            <h4 class="card-title"><font-awesome-icon icon="fa-regular fa-calendars"  class="mr-2" />{{$t("StaffProfile.UpcomingAppointments") }}</h4>
          </template>
          <template v-slot:body>
            <b-nav pills fill vertical>
              <table v-if="model && model.upcomingAppointments && model.upcomingAppointments.length > 0" class="table">
                <thead>
                  <tr>
                    <td style="width: 15%"><b>{{ $t("Customer") }}</b></td>
                    <td style="width: 15%"><b>{{ $t("Treatment") }}</b></td>
                    <td style="width: 15%"><b>{{ $t("Date") }}</b></td>
                    <td style="width: 5%"></td>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(appointment, index) in model.upcomingAppointments"
                      :key="index">
                    <td>
                      <span class="kt-widget11__sub">
                        {{ appointment.customerName }}
                      </span>
                    </td>
                    <td>
                      <span class="kt-widget11__sub">
                        {{ appointment.operation }}
                      </span>
                    </td>
                    <td>
                      <span class="kt-widget11__sub">
                        {{ appointment.dateAsString }}
                      </span>
                    </td>
                    <td>
                      <button type="button"
                              class="btn btn-primary"
                              @click="getAppointments(appointment.customerID)">
                        <i class="fa fa-search"></i>
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
              <no-data v-else :no-data-text="$t('StaffProfile.NoData')"></no-data>
            </b-nav>
          </template>
        </iq-card>
      </b-col>
    </b-row>

    <!--<b-col md="9">
      <router-view :key="$router.currentRoute.meta.childName"></router-view>
    </b-col>
    </b-row>-->
  </b-container>
</template>
<script>
  import authService from '../../../services/auth'
  import staffService from '../../../services/staff'
  import multimediaService from '../../../services/multimedia';

  import NoData from '../../../components/shared/NoData';

  export default {
    name: 'StaffProfile',
    props: { staffId: String },
    components: {
      NoData
    },
    data() {
      return {
        customerInfo: {},
        profilePicture: {},
        isEdit: false,
        editModel: {
          id: "",
          name: null,
          phoneNumber: "",
          email: "",
        },
        model: {
          staffId: '',
          name: '',
          phoneNumber: '',
          email: '',
          profilePicture: null,
          timeSpan: null,
          monthlyTargetPercentage: 0,
          monthlyTargetTotal: 0,
          monthlyTarget: 0,
          clinicTargetPercentage: 0,
          enrolledcustomerCount: 0,
          customersResponsibleForCount: 0,
          appointmentsNumber: 0,
          proposeNumber: 0,
          totalSalesNumber: 0,
          totalCollectionNumber: 0,
          mainCurrencySymbol: '',
          upcomingAppointments: [],
          proposes: [],
          notes: [],
        },
        isSubmitting: false
      };
    },
    methods: {
      getStaffProfile() {
        staffService.getStaffProfile().then(response => {
          this.model = response;
        });
      },
      onFileChanged(event) {
        this.selectedFile = event.target.files[0];
        this.uploadProfilePicture();
      },
      uploadProfilePicture() {
        const formData = new FormData();
        formData.append(
          "staffProfilePicture",
          this.selectedFile,
          this.selectedFile.name
        );
        formData.append("staffId", this.model.staffId);
        formData.append("uploadedFrom", "StaffSummary");

        multimediaService.uploadStaffProfilePicture(formData)
          .then((response) => {
            if (response) {
              this.$toastr.success(this.$t("Success"));

              this.getStaffProfile();
            } else {
              this.$toastr.error(this.$t("Error"));
            }
          });
      },
      openEditModel() {
        const id = this.model.staffId;
        const name = this.model.name;
        const phone = this.model.phoneNumber;
        const email = this.model.email;
        this.editModel = {
          id: id,
          name: name,
          phoneNumber: phone,
          email: email,
        };

        this.isEdit = true;
      },
      onCancelEdit() {
        this.isEdit = false;
        this.editModel = {
          id: "",
          name: "",
          phoneNumber: "",
          email: "",
        };
      },
      onSubmitEditForm() {
        this.isSubmitting = true;
        staffService.updateStaffProfileFast(this.editModel).then(response => {
          if (response) {
            this.$toastr.success(this.$t("Success"));
            this.model.name = this.editModel.name;
            this.model.phoneNumber = this.editModel.phoneNumber;
            this.model.email = this.editModel.email;

            this.onCancelEdit();
          } else {
            this.$toastr.error(this.$t("Error"));
          }
        }).finally(() => { this.isSubmitting = false });
      },
      logout() {
        authService.logout();
      },
      noteToggle(note) {
        if (note) {
          this.$axios
          staffService.noteToggle(note.id)
            .then((result) => {
              const res = result?.data?.data;
              if (res) {
                this.$toastr.success(this.$t("Success"));
                note.isCompleted = res.isCompleted;
              } else {
                this.$toastr.success(this.$t("Success"));
                note.isCompleted = !note.isCompleted;
              }
            });
        }
      },
      getNotes(customerId) {
        if (customerId) {
          this.$router.push({ name: 'customerSummary.notes', params: { customerId: customerId } });
        }
      },
      getAppointments(customerId) {
        if (customerId) {
          this.$router.push({ name: 'customerSummary.appointments', params: { customerId: customerId } });
        }
      },
      onNavigatePasswordSettings() {
        this.$router.push({ name: "staffProfile.passwordSettings", params: { staffId: this.model.staffId } });
      },
    },
    watch: {
      staffId: function () {
        this.getStaffProfile();
      }
    },
    mounted: function () {
      this.getStaffProfile();
    }
  }
</script>
