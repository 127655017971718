<template>
  <b-alert id="no-data-alert" :show="true" variant="warning" style="margin-bottom: 0px !important">
    <div class="iq-alert-icon">
      <font-awesome-icon icon="fa-regular fa-triangle-exclamation" size="2xl" />
    </div>
    <div class="iq-alert-text text-dark no-data-text">{{ placeholder }}</div>
  </b-alert>
</template>

<script>
  export default {
    name: "NoData",
    props: {
      noDataText: String
    },
    computed: {
      placeholder() {
        return this.noDataText ?? this.$t("NoSearchResults");
      }
    }
  }
</script>

<style scoped>
  #no-data-alert .alert-warning {
    color: #e9a90f !important;
    border-color: #fff2b2 !important;
    background-color: #fff7e0 !important;
  }

  #no-data-alert .no-data-text {
    font-size: 1rem !important;
    font-weight: 500 !important;
  }
</style>
